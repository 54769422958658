import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Segmented,
  Space,
  Typography,
} from "antd";
import {
  useCurrentLocale,
  useCurrentUser,
  useSubscriptionCreate,
} from "../../lib/hooks";

import makePlans from "./pricing";
import en from "./pricing.en";
import es from "./pricing.es";
import { CheckOutlined } from "@ant-design/icons";
import Link from "next/link";
import { useState } from "react";
import redirect from "../../lib/redirect";
import { FormattedMessage } from "react-intl";
import { routerReload } from "../../lib/routes";

const locales = { en, es, pt: es, ru: en };

export function SubscriptionPlans() {
  const plans = makePlans();
  const { user } = useCurrentUser();
  const { currentLocale } = useCurrentLocale();
  const [period, setPeriod] = useState("month");
  const [generateLink, { loading }] = useSubscriptionCreate();

  if (!plans) return null;

  const isAnnual = period === "year";
  const l: any = locales[currentLocale].pricing;

  const onPressPlan = (plan?: string) => {
    generateLink({ variables: { plan, period } }).then(({ data }) => {
      if (data?.subscriptionCreate?.link) {
        redirect(data.subscriptionCreate.link);
      } else {
        routerReload();
      }
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} style={{ textAlign: "center", marginTop: 16 }}>
        <Typography.Title level={2}>
          <FormattedMessage
            id="subscriptions.selectPlan"
            defaultMessage="Select a plan!"
          />
        </Typography.Title>
        <Typography.Paragraph>
          <FormattedMessage
            id="subscriptions.selectPlan.hint"
            defaultMessage="hint"
          />
        </Typography.Paragraph>
      </Col>
      <Col span={24} style={{ textAlign: "end" }}>
        <Segmented
          value={period}
          options={[
            { label: l.monthly, value: "month" },
            {
              label: (
                <>
                  <div>{l.annual}</div>
                  <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    {l.discount}
                  </Typography.Text>
                </>
              ),
              value: "year",
            },
          ]}
          onChange={(v) => setPeriod(v.toString())}
        />
      </Col>
      {plans.map((plan) => {
        const card = (
          <Card
            title={
              <Typography.Title level={3}>
                {l[plan.name].title}
              </Typography.Title>
            }
            style={{ height: "100%", borderRadius: "10px" }}
          >
            <Typography.Title level={4}>
              {l[plan.name].subtitle}
            </Typography.Title>
            <Typography.Paragraph>
              {l[plan.name].description}
            </Typography.Paragraph>

            {typeof plan.price === "string" ? (
              <Space>
                <Typography.Title level={3}>
                  {l[plan.name].price}
                </Typography.Title>
              </Space>
            ) : (
              <Space>
                <div className={isAnnual ? "crossed" : ""}>
                  <Typography.Title level={3}>
                    <span
                      style={{
                        fontWeight: 100,
                        fontSize: 14,
                        lineHeight: "24px",
                        verticalAlign: "top",
                      }}
                    >
                      US
                    </span>
                    ${plan.price.month}
                  </Typography.Title>
                </div>
                {isAnnual && (
                  <Typography.Title level={3}>
                    <span
                      style={{
                        fontWeight: 100,
                        fontSize: 14,
                        lineHeight: "24px",
                        verticalAlign: "top",
                      }}
                    >
                      US
                    </span>
                    ${plan.price.year}
                  </Typography.Title>
                )}
              </Space>
            )}

            <Typography.Paragraph>
              {typeof plan.price === "string" ? (
                <Link href={`https://aragro.com/${currentLocale}/contact`}>
                  <Button
                    type="primary"
                    className="btn-complete"
                    disabled={loading}
                  >
                    {l.cta[plan.cta]}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="primary"
                  className="btn-complete"
                  onClick={() => onPressPlan(plan.name)}
                  loading={loading}
                  disabled={loading}
                >
                  {l.cta[plan.cta]}
                </Button>
              )}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              {l[plan.name].includes}
            </Typography.Paragraph>

            <Space direction="vertical">
              {plan.features.map((feature) => (
                <Typography.Text key={feature}>
                  <CheckOutlined /> {l[plan.name].features[feature]}
                </Typography.Text>
              ))}
            </Space>
          </Card>
        );

        return (
          <Col key={plan.name} span={8}>
            {plan.featured ? (
              <div id="featured-plan" style={{ height: "100%" }}>
                <Badge.Ribbon text={l.featured}>{card}</Badge.Ribbon>
                <style jsx>
                  {`
                    #featured-plan :global(.ant-ribbon-wrapper) {
                      height: 100%;
                    }
                  `}
                </style>
              </div>
            ) : (
              card
            )}
          </Col>
        );
      })}

      {!user?.tenants.length && user?.settings.company_name && (
        <Col span={24} style={{ textAlign: "center" }}>
          <Divider />

          <Button
            type="primary"
            className="btn-complete"
            onClick={() => onPressPlan()}
            loading={loading}
            disabled={loading}
          >
            <FormattedMessage
              id="subscriptions.freePlan"
              defaultMessage="Try for free"
            />
          </Button>
        </Col>
      )}
    </Row>
  );
}
