module.exports = function () {
  return [
    {
      name: "basic",
      price: {
        month: 100,
        year: 80,
      },
      featured: true,
      cta: "start",
      ctaLink: `${process.env.APP_URL}/user/welcome?plan=basic&period=month`,
      borderColor: "#7667F7",
      features: [
        "1hr_onboarding",
        "unlimited_agro_activities",
        "field_cost_tracking",
        "pest_disease",
        "weather",
        "maps",
        "one_farm",
        "two_users",
      ],
      detailedFeatures: {
        characteristics: {
          users: "max_2",
          farms: 1,
          languages: "multi_language",
          currencies: "multi_currency",
          imports: true,
          exports: true,
          integrations: false,
        },
        work_orders: {
          field: true,
          harvest: false,
          processing: false,
          machinery: false,
          infrastructure: false,
          general: false,
        },
        activities: {
          agro: true,
          machinery: false,
          infrastructure: false,
          general: false,
          custom: false,
          progress_tracking: false,
          metrics: false,
          payment_rules_daily: false,
          payment_rules_piecework: false,
          advanced_wo: false,
          planning: false,
          irrigation: true,
        },
        fields: {
          stages: false,
          crop_varieties: true,
          plant_inventory: false,
          link_to_cost_centers: false,
          field_attributes: true,
        },
        inventory: {
          product_catalog: true,
          product_variants: false,
          security_certification: true,
          stock: false,
          multi_stock: false,
          inventory_requests: false,
          purchase_orders: false,
          transfers: false,
          adjustments: false,
          sales: false,
        },
        hr: {
          employees: false,
          employee_groups: false,
          attendance: false,
          leave_assignments: false,
          work_schedules: false,
          contracts: false,
          piecework_payments: false,
          payroll: false,
          efficiency_tracking: false,
          holidays: false,
          overtime: false,
          pre_payroll: false,
        },
        finance: {
          cost_centers: false,
          payments: false,
          counterparties: false,
          fiscal_years: false,
          expense_orders: false,
          accounting: false,
          tax_plans: false,
        },
        other: {
          maps: false,
          pest_disease: false,
          weather: false,
        },
      },
    },
    {
      name: "pro",
      price: {
        month: 200,
        year: 160,
      },
      cta: "start",
      ctaLink: `${process.env.APP_URL}/user/welcome?plan=pro&period=month`,
      borderColor: "#00ACAA",
      features: [
        "2hr_onboarding",
        "harvest",
        "crops_varieties",
        "activity_progress",
        "labour_efficiency",
        "perm_temp_workers",
        "attendance",
        "salary",
        "inventory",
        "general_activities",
        "two_farms",
        "five_users",
      ],
      detailedFeatures: {
        characteristics: {
          users: "max_5",
          farms: 2,
          languages: "multi_language",
          currencies: "multi_currency",
          imports: true,
          exports: true,
          integrations: false,
        },
        work_orders: {
          field: true,
          harvest: true,
          processing: false,
          machinery: false,
          infrastructure: false,
          general: false,
        },
        activities: {
          agro: true,
          machinery: false,
          infrastructure: false,
          general: true,
          custom: true,
          progress_tracking: true,
          metrics: false,
          payment_rules_daily: true,
          payment_rules_piecework: false,
          advanced_wo: true,
          planning: false,
          irrigation: true,
        },
        fields: {
          stages: false,
          crop_varieties: true,
          plant_inventory: true,
          link_to_cost_centers: true,
          field_attributes: true,
        },
        inventory: {
          product_catalog: true,
          product_variants: true,
          security_certification: true,
          stock: true,
          multi_stock: false,
          inventory_requests: false,
          purchase_orders: true,
          transfers: false,
          adjustments: true,
          sales: true,
        },
        hr: {
          employees: true,
          employee_groups: true,
          attendance: true,
          leave_assignments: false,
          work_schedules: false,
          contracts: false,
          piecework_payments: false,
          payroll: false,
          efficiency_tracking: true,
          holidays: false,
          overtime: true,
          pre_payroll: true,
        },
        finance: {
          cost_centers: true,
          payments: true,
          counterparties: true,
          fiscal_years: true,
          expense_orders: true,
          accounting: true,
          tax_plans: true,
        },
        other: {
          maps: true,
          pest_disease: true,
          weather: true,
        },
      },
    },
    {
      name: "enterprise",
      price: "cotiza",
      cta: "contact",
      ctaLink: "request-information",
      borderColor: "#FDCD78",
      features: [
        "custom_onboarding",
        "harvest_processing",
        "machinery",
        "planning",
        "budgets",
        "multi_warehouse",
        "inventory_requests",
        "piecework_wage",
        "schedules_holidays",
        "payroll",
        "accounting_tax",
        "integrations",
        "unlimited_farms",
        "unlimited_users",
      ],
      detailedFeatures: {
        characteristics: {
          users: "quote",
          farms: "quote",
          languages: "multi_language",
          currencies: "multi_currency",
          imports: true,
          exports: true,
          integrations: true,
        },
        work_orders: {
          field: true,
          harvest: true,
          processing: true,
          machinery: true,
          infrastructure: true,
          general: false,
        },
        activities: {
          agro: true,
          machinery: true,
          infrastructure: true,
          general: true,
          custom: true,
          progress_tracking: true,
          metrics: true,
          payment_rules_daily: true,
          payment_rules_piecework: true,
          advanced_wo: true,
          planning: true,
          irrigation: true,
        },
        fields: {
          stages: true,
          crop_varieties: true,
          plant_inventory: true,
          link_to_cost_centers: true,
          field_attributes: true,
        },
        inventory: {
          product_catalog: true,
          product_variants: true,
          security_certification: true,
          stock: true,
          multi_stock: true,
          inventory_requests: true,
          purchase_orders: true,
          transfers: true,
          adjustments: true,
          sales: true,
        },
        hr: {
          employees: true,
          employee_groups: true,
          attendance: true,
          leave_assignments: true,
          work_schedules: true,
          contracts: true,
          piecework_payments: true,
          payroll: true,
          efficiency_tracking: true,
          holidays: true,
          overtime: true,
          pre_payroll: true,
        },
        finance: {
          cost_centers: true,
          payments: true,
          counterparties: true,
          fiscal_years: true,
          expense_orders: true,
          accounting: true,
          tax_plans: true,
        },
        other: {
          maps: true,
          pest_disease: true,
          weather: true,
        },
      },
    },
  ];
};
