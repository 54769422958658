module.exports = {
  pricing: {
    meta: {
      title: "Pricing",
      description:
        "Aragro's mission is to help farmers in Latin America modernize their daily operations through our platform.",
    },
    title: "Pricing",
    hero: {
      title: "The digital machete to modernize your farm",
      subtitle:
        "Choose the plan that best adapts to your farms needs and start digitizing your farm today!",
      freeText: "Track up to 20 activities for free!",
    },
    freeCTA: "Try for free",
    monthly: "Monthly",
    annual: "Annual",
    discount: "Save 20%",
    per_month: "per month, paid annually",
    cta: {
      start: "Start now",
      contact: "Contact Sales",
    },
    featured: "Most popular",
    compare: "Compare and learn more about our plans",
    faq: {
      title: "Frequently Asked Questions",
      items: {
        methods: {
          title: "What are the payment methods I can use to purchase Aragro?",
          answer:
            'We use Stripe to process online payments. Therefore, we accept any credit or debit card that Stripe allows. For "Harvest" plan customers, we can also accept additional payment methods.',
        },
        security: {
          title:
            "How do I know if my information is secure, will not be lost and is confidential?",
          answer:
            "Aragro prioritizes the security of its customers' data. We host our servers on Amazon Web Services, taking advantage of their security protocols.",
        },
        newFunctionality: {
          title:
            "What happens if my operation grows and I need more functionality?",
          answer:
            "Congratulations on your growth! You can upgrade to a higher plan at any time to access more features and personalized support.",
        },
        cancellation: {
          title: "Can I cancel my subscription at any time?",
          answer:
            "Yes, you may cancel at any time prior to the charge of the monthly fee. Once the payment is made, we will not be able to make any refund.",
        },
        cancellationData: {
          title:
            "What happens with my data if I decide to cancel my subscription?",
          answer:
            "If you decide to cancel your subscription, you can request your details from our support team. You will have a maximum of 10 days after canceling to request it.",
        },
        account: {
          title: "Can I receive an invoice in my company's name?",
          answer:
            "Yes, at the moment of registering your payment information, you will be able to enter your company's name and RUC and month by month you will receive the invoice corresponding to the payment. ",
        },
      },
    },
    features: {
      groups: {
        characteristics: {
          title: "Characteristics",
          users: "Users",
          farms: "Farms",
          languages: "Languages",
          currencies: "Currencies",
          imports: "Imports",
          exports: "Exports",
          integrations: "Integrations",
        },
        work_orders: {
          title: "Work Orders",
          field: "Field Work Order",
          harvest: "Harvest Work Order",
          processing: "Processing Work Order",
          machinery: "Machinery Work Order",
          infrastructure: "Infrastructure Work Order",
          general: "General Work Order",
        },
        activities: {
          title: "Activities",
          agro: "Agricultural Activities",
          machinery: "Machinery Activities",
          infrastructure: "Infrastructure Activities",
          general: "General Activities",
          custom: "Custom Activities",
          progress_tracking: "Progress Tracking",
          metrics: "Metrics",
          payment_rules_daily: "Payment Rules (Daily)",
          payment_rules_piecework: "Payment Rules (Piecework)",
          advanced_wo: "Advanced Work Orders",
          planning: "Planning",
          irrigation: "Irrigation",
        },
        fields: {
          title: "Fields",
          stages: "Crop stages",
          crop_varieties: "Crop varieties",
          plant_inventory: "Plant inventory",
          link_to_cost_centers: "Link to cost centers",
          field_attributes: "Field attributes",
        },
        inventory: {
          title: "Inventory",
          product_catalog: "Product Catalog",
          product_variants: "Product Variants",
          security_certification: "Security and Certification",
          stock: "Stock",
          multi_stock: "Multiple Stock Locations",
          inventory_requests: "Inventory Requests",
          purchase_orders: "Purchase Orders",
          transfers: "Transfers",
          adjustments: "Adjustments",
          sales: "Sales",
        },
        hr: {
          title: "Human Resources",
          employees: "Employees",
          employee_groups: "Employee Groups",
          attendance: "Attendance",
          leave_assignments: "Leave Assignments",
          work_schedules: "Work Schedules",
          contracts: "Contracts",
          piecework_payments: "Piecework Payments",
          efficiency_tracking: "Efficiency Tracking",
          payroll: "Payroll",
          holidays: "Holidays",
          overtime: "Overtime",
          pre_payroll: "Pre-Payroll",
        },
        finance: {
          title: "Finance",
          cost_centers: "Cost Centers",
          payments: "Payments and Invoices",
          counterparties: "Counterparties",
          fiscal_years: "Fiscal Years",
          expense_orders: "Expense Orders",
          accounting: "Accounting",
          tax_plans: "Tax Plans",
        },
        other: {
          title: "Other",
          maps: "Maps and GIS",
          pest_disease: "Pests and Diseases",
          weather: "Weather",
        },
      },
      values: {
        max_2: "Max 2",
        max_5: "Max 5",
        max_10: "Max 10",
        multi_language: "Multi-language",
        multi_currency: "Multi-currency",
        quote: "Quote",
      },
    },
    basic: {
      title: "Seed",
      subtitle:
        "Throw out the notebook and start your journey to digitalization!",
      description: "The easiest way to manage your farm’s day-to-day",
      includes: "Includes:",
      price: "Start now!",
      features: {
        "1hr_onboarding": "Complimentary 1 hr onboarding session",
        unlimited_agro_activities:
          "Unlimited tracking of agricultural activities",
        field_cost_tracking: "Cost tracking by field",
        pest_disease: "Monitoring of pests, diseases, and weeds",
        weather: "Weather data & trend analysis",
        maps: "Interactive farm maps",
        one_farm: "Management for 1 farm",
        two_users: "Access for 2 users",
      },
    },
    pro: {
      title: "Growth",
      subtitle: "Take your farm to the next level.",
      description:
        "Advanced tools to optimize your farm operations and enhance efficiency of all your activities.",
      includes: "Everything in Seed, plus:",
      price: "Start now!",
      features: {
        "2hr_onboarding": "Complimentary 2 hr onboarding session",
        harvest: "Harvest management",
        crops_varieties: "Manage different crops & varieties",
        activity_progress: "Activity progress tracking",
        labour_efficiency: "Labor efficiency and input usage metrics",
        perm_temp_workers: "Management of permanent and temporary workers",
        attendance: "Attendance tracking by day and activity",
        salary: "Salary schemes",
        inventory: "Purchases and inventory management",
        general_activities: "General activities and costs",
        two_farms: "Management for 2 farms",
        five_users: "Access for 5 users",
      },
    },
    enterprise: {
      title: "Harvest",
      subtitle: "The sharpest machete.",
      description:
        "Advanced functionalities and personalized support to unlock the full potential of your crops",
      includes: "Everything in Growth, plus:",
      price: "Get a quote!",
      features: {
        custom_onboarding: "Personalized onboarding",
        harvest_processing: "Harvest processing & sales",
        machinery: "Machinery and infrastructure management",
        planning: "Season & activity planning",
        budgets: "Agricultural budgeting",
        multi_warehouse: "Multiple warehouses",
        inventory_requests: "Inventory requests",
        piecework_wage: "Piecework wages",
        schedules_holidays: "Schedules, holidays, and absences",
        payroll: "Payroll",
        accounting_tax: "Accounting and tax plans",
        integrations: "Integration with other systems",
        unlimited_farms: "Management for unlimited farms",
        unlimited_users: "Access for unlimited users",
      },
    },
    ctaSection: {
      title:
        "Be part of our community that chose<br>Aragro's innovation and efficiency",
      subtitle:
        "More than 90,000 hectares have already improved their efficiency with Aragro, maximizing productivity and optimizing every aspect of their farming operations. Our community continues to grow, driving real change in the way agriculture is managed today to prepare for the changes of the future.",
    },
    miller: {
      quote:
        "''Although adopting digital tools may seem daunting and complicated, the world is changing. Today, everything is done through phones or computers, and those who don't adapt will be left behind. Going digital is the best option, and there will never be a perfect time to do it. Aragro is easy to use, adapts well to farmers and has helped us simplify our operations.''",
    },
  },
};
