module.exports = {
  pricing: {
    meta: {
      title: "Precios",
      description:
        "Aragro's mission is to help farmers in Latin America modernize their daily operations through our platform.",
    },
    title: "Precios",
    hero: {
      title: "El Machete Digital para modernizar tu finca",
      subtitle:
        "¡Elige el plan que mejor se adapte a tus necesidades y empieza a digitalizar tu finca hoy mismo!",
      freeText: "¡Registra hasta 20 actividades totalmente gratis!",
    },
    freeCTA: "Pruébalo gratis",
    monthly: "Mensual",
    annual: "Anual",
    discount: "Ahorra 20%",
    per_month: "al mes, pagando anualmente",
    cta: {
      start: "Comenzar ahora",
      contact: "Contactar Ventas",
    },
    featured: "Más popular",
    compare: "Compara y conozca mas de nuestros planes",
    faq: {
      title: "Preguntas Frecuentes",
      items: {
        methods: {
          title:
            "¿Cuáles son los métodos de pago que puedo utilizar para adquirir Aragro?",
          answer:
            'Utilizamos Stripe para procesar los pagos en línea. Por lo tanto, aceptamos cualquier tarjeta de crédito o débito que Stripe permita. Para clientes de "Cosecha" plan, también podemos aceptar métodos de pago adicionales.',
        },
        security: {
          title:
            "¿Cómo sé si mi información está segura, no la voy a perder y es confidencial?",
          answer:
            "Aragro prioriza la seguridad de los datos de sus clientes. Alojamos nuestros servidores en Amazon Web Services, aprovechando sus protocolos de seguridad.",
        },
        newFunctionality: {
          title:
            "¿Qué sucede si mi operación crece y necesito más funcionalidades?",
          answer:
            "¡Felicitaciones por tu crecimiento! Podrás cambiarte a un plan superior en cualquier momento para acceder a más funcionalidades y soporte personalizado.",
        },
        cancellation: {
          title: "¿Puedo cancelar mi subscripción en cualquier momento?",
          answer:
            "Si, podrás cancelar cuando desees previo al cargo de la menusalidad. Una vez se realice el pago, no podremos efectuar ningún reembolso.",
        },
        cancellationData: {
          title:
            "¿Qué sucede con mi información si decido cancelar mi subscipción?",
          answer:
            "Si decides cancelar tu subscripción, podrás solicitar tus datos con nuestro equipo de soporte. Tendrás un máximo de 10 días luego de cancelar para solicitarlo. ",
        },
        account: {
          title:
            "¿Tengo posibilidad de recibir una factura a nombre de mi empresa?",
          answer:
            "Si, al momento de registrar tu información de pago, podrás ingresar nombre y RUC de tu empresa y mes a mes recibirás la factura correspondiente al pago.",
        },
      },
    },
    features: {
      groups: {
        characteristics: {
          title: "Características",
          users: "Usuarios",
          farms: "Finca",
          languages: "Idiomas",
          currencies: "Monedas",
          imports: "Importaciones",
          exports: "Exportaciones",
          integrations: "Integraciones",
        },
        work_orders: {
          title: "Ordenes de trabajo",
          field: "Órden de Trabajo de Campo",
          harvest: "Órden de Cosecha",
          processing: "Órden de Procesamiento",
          machinery: "Órden de Maquinaria",
          infrastructure: "Órden de Infraestructura",
          general: "Órden General",
        },
        activities: {
          title: "Actividades",
          agro: "Activitidades Agrícolas",
          machinery: "Actividades de Maquinaria",
          infrastructure: "Actividades de Infraestructura",
          general: "Actividades Generales",
          custom: "Actividades Personalizadas",
          progress_tracking: "Seguimiento de Avances",
          metrics: "Métricas",
          payment_rules_daily: "Nórmas de Pago",
          payment_rules_piecework: "Reglas de Pago (Destajo)",
          advanced_wo: "Configuración Avanzada de Órdenes de Trabajo",
          planning: "Planificación",
          irrigation: "Pulverización",
        },
        fields: {
          title: "Campos",
          stages: "Etapas de los Cutlivos",
          crop_varieties: "Variedades de Cultivos",
          plant_inventory: "Inventario de Plantas",
          link_to_cost_centers: "Enlazar a Centro de Costos",
          field_attributes: "Atributos de Campo",
        },
        inventory: {
          title: "Inventario",
          product_catalog: "Catálogo de Productos",
          product_variants: "Variantes de Productos",
          security_certification: "Seguridad y Certificación",
          stock: "Stock",
          multi_stock: "Múltiples Ubicaciones de Stock",
          inventory_requests: "Solicitudes de Inventario",
          purchase_orders: "Ordenes de Compra",
          transfers: "Transferencias",
          adjustments: "Ajustes",
          sales: "Ventas",
        },
        hr: {
          title: "Recursos Humanos",
          employees: "Empleados",
          employee_groups: "Grupos",
          attendance: "Asistencia",
          leave_assignments: "Asignaciones de Vacaciones",
          work_schedules: "Horarios de Trabajo",
          contracts: "Contratos",
          piecework_payments: "Pagos a Destajo",
          payroll: "Nomina",
          efficiency_tracking: "Seguimiento de la Eficiencia",
          holidays: "Vacaciones",
          overtime: "Horas Extras",
          pre_payroll: "Pre-Nomina",
        },
        finance: {
          title: "Finanzas",
          cost_centers: "Centros de Costes",
          payments: "Pagos y Cobros",
          counterparties: "Contrapartidas",
          fiscal_years: "Años Fiscales",
          expense_orders: "Ordenes de Gastos",
          accounting: "Contabilidad",
          tax_plans: "Planes Fiscales",
        },
        other: {
          title: "Otros",
          maps: "Mapas y SIG",
          pest_disease: "Plagas y enfermedades",
          weather: "Clima",
        },
      },
      values: {
        max_2: "Hasta 2",
        max_5: "Hasta 5",
        max_10: "Hasta 10",
        multi_language: "Multi-idioma",
        multi_currency: "Multi-moneda",
        quote: "Cotizar",
      },
    },
    basic: {
      title: "Semilla",
      subtitle: "¡Tira la libreta e inicia tu camino hacia la digitalización!",
      description:
        "La manera más sencilla de gestionar las actividades del día a día de tu finca.",
      includes: "Incluye:",
      price: "Comenzar ahora",
      features: {
        "1hr_onboarding": "Sesión de implementación gratuita de 1 hora",
        unlimited_agro_activities:
          "Registro ilimitado de actividades agrícolas",
        field_cost_tracking: "Seguimiento de costos por parcela",
        pest_disease: "Monitoreo de plagas, enfermedades y malezas",
        weather: "Datos climáticos & tendencias",
        maps: "Mapas interactivos de la finca",
        one_farm: "Gestión para 1 finca",
        two_users: "Acceso para 2 usuarios",
      },
    },
    pro: {
      title: "Cultivo",
      subtitle: "¡Lleva tu finca al siguiente nivel!",
      description:
        "Herramientas avanzadas para optimizar operaciones de tu finca y mejorar la eficiencia de tus actividades.",
      includes: "Lo del Plan Semilla, más:",
      price: "Comenzar ahora",
      features: {
        "2hr_onboarding": "Sesión de implementación gratuita de 2 horas",
        harvest: "Gestión de cosecha",
        crops_varieties: "Administración de cultivos & variedades",
        activity_progress: "Seguimiento de avance de labores",
        labour_efficiency: "Métricas avanzadas de eficiencia laboral e insumos",
        perm_temp_workers: "Gestión de trabajadores fijos y temporales",
        attendance: "Asistencia por día y actividad",
        salary: "Libro de salario",
        inventory: "Compras y existencias de inventarios",
        general_activities: "Actividades y costos generales",
        two_farms: "Gestión para 2 fincas",
        five_users: "Acceso para 5 usuarios",
      },
    },
    enterprise: {
      title: "Cosecha",
      subtitle: "¡El machete más filoso!",
      description:
        "Funcionalidades avanzadas y soporte personalizado para aprovechar al máximo el potencial de tus cultivos.",
      includes: "Lo del Plan Cultivo, más:",
      price: "¡Cotiza!",
      features: {
        custom_onboarding: "Implementación personalizada",
        harvest_processing: "Procesamiento de cosecha y ventas",
        machinery: "Gestión de maquinaria e infraestructura",
        planning: "Planificación de actividades",
        budgets: "Presupuesto agrícola",
        multi_warehouse: "Múltiples almacenes",
        inventory_requests: "Solicitudes de inventario",
        piecework_wage: "Salarios por destajo",
        schedules_holidays: "Horarios, feriados y ausencias",
        payroll: "Planilla",
        accounting_tax: "Contabilidad y plan de impuestos",
        integrations: "Integraciones con otros sistemas",
        unlimited_farms: "Gestion para fincas ilimitadas",
        unlimited_users: "Acceso para usuarios illimitados",
      },
    },
    ctaSection: {
      title:
        "Sé parte de nuestra comunidad que<br>eligió la innovación y eficiencia de Aragro",
      subtitle:
        "Más de 90,000 hectáreas ya han mejorado su eficiencia con Aragro, logrando maximizar la productividad y optimizar cada aspecto de sus operaciones agrícolas. Nuestra comunidad sigue creciendo, impulsando un cambio real en la forma en que se maneja la agricultura hoy en día para prepararse para los cambios del futuro.",
    },
    miller: {
      quote:
        "‘’Aunque adoptar herramientas digitales puede parecer desalentador y complicado, el mundo está cambiando. Hoy en día, todo se hace a través de teléfonos u ordenadores, y los que no se adapten se quedarán atrás. Pasarse a un sistema digital es la mejor opción, y nunca habrá un momento perfecto para hacerlo.<br>Aragro es fácil de usar, se adapta bien a los agricultores y nos ha ayudado a simplificar nuestras  operaciones.’’",
    },
  },
};
